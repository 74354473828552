import Layout from "../../../../src/components/Layout";
import { DataPageSidebarLinks } from "../../../../src/helpers/constants";
import { Link } from 'gatsby';
import InfoCard from "../../../../src/components/InfoCard";
import { ExternalResourcesData } from "../../../../src/helpers/external-resources";
import * as React from 'react';
export default {
  Layout,
  DataPageSidebarLinks,
  Link,
  InfoCard,
  ExternalResourcesData,
  React
};