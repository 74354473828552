import Layout from "../../../../src/components/Layout";
import InfoCard from "../../../../src/components/InfoCard";
import { DataPageSidebarLinks } from "../../../../src/helpers/constants";
import datagaps from "../../../../src/images/research-datagaps.png";
import democratizing from "../../../../src/images/research-democratizing.png";
import covid from "../../../../src/images/research-covid.jpg";
import { Link } from 'gatsby';
import * as React from 'react';
export default {
  Layout,
  InfoCard,
  DataPageSidebarLinks,
  datagaps,
  democratizing,
  covid,
  Link,
  React
};