import Layout from "../../../../src/components/Layout";
import InfoCard from "../../../../src/components/InfoCard";
import { AboutSidebarLinks } from "../../../../src/helpers/constants";
import EDGI from "../../../../src/images/EDGI-logo.webp";
import Packard from "../../../../src/images/funders-packard.png";
import ESIP from "../../../../src/images/funders-esip.png";
import CSS from "../../../../src/images/funders-css.png";
import NEU from "../../../../src/images/funders-neu.png";
import SBU from "../../../../src/images/funders-sbu.png";
import DDCF from "../../../../src/images/funders-ddcf.png";
import EDGIperson from "../../../../src/images/funders-EDGIperson.png";
import * as React from 'react';
export default {
  Layout,
  InfoCard,
  AboutSidebarLinks,
  EDGI,
  Packard,
  ESIP,
  CSS,
  NEU,
  SBU,
  DDCF,
  EDGIperson,
  React
};