import { Link } from 'gatsby';
import Layout from "../../../../src/components/Layout";
import InfoCard from "../../../../src/components/InfoCard";
import { DataPageSidebarLinks } from "../../../../src/helpers/constants";
import watershed from "../../../../src/images/notebooks-watershed.png";
import crossprog from "../../../../src/images/notebooks-cross-program.png";
import ghg from "../../../../src/images/notebooks-ghg.png";
import * as React from 'react';
export default {
  Link,
  Layout,
  InfoCard,
  DataPageSidebarLinks,
  watershed,
  crossprog,
  ghg,
  React
};