exports.components = {
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-about-contact-js": () => import("./../../../src/pages/about/contact.js" /* webpackChunkName: "component---src-pages-about-contact-js" */),
  "component---src-pages-about-faq-mdx": () => import("./../../../src/pages/about/faq.mdx" /* webpackChunkName: "component---src-pages-about-faq-mdx" */),
  "component---src-pages-about-funding-mdx": () => import("./../../../src/pages/about/funding.mdx" /* webpackChunkName: "component---src-pages-about-funding-mdx" */),
  "component---src-pages-about-index-mdx": () => import("./../../../src/pages/about/index.mdx" /* webpackChunkName: "component---src-pages-about-index-mdx" */),
  "component---src-pages-about-people-mdx": () => import("./../../../src/pages/about/people.mdx" /* webpackChunkName: "component---src-pages-about-people-mdx" */),
  "component---src-pages-about-press-mdx": () => import("./../../../src/pages/about/press.mdx" /* webpackChunkName: "component---src-pages-about-press-mdx" */),
  "component---src-pages-data-congress-mdx": () => import("./../../../src/pages/data/congress.mdx" /* webpackChunkName: "component---src-pages-data-congress-mdx" */),
  "component---src-pages-data-notebooks-mdx": () => import("./../../../src/pages/data/notebooks.mdx" /* webpackChunkName: "component---src-pages-data-notebooks-mdx" */),
  "component---src-pages-data-reports-es-mdx": () => import("./../../../src/pages/data/reports-es.mdx" /* webpackChunkName: "component---src-pages-data-reports-es-mdx" */),
  "component---src-pages-data-reports-mdx": () => import("./../../../src/pages/data/reports.mdx" /* webpackChunkName: "component---src-pages-data-reports-mdx" */),
  "component---src-pages-data-research-mdx": () => import("./../../../src/pages/data/research.mdx" /* webpackChunkName: "component---src-pages-data-research-mdx" */),
  "component---src-pages-data-resources-mdx": () => import("./../../../src/pages/data/resources.mdx" /* webpackChunkName: "component---src-pages-data-resources-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-learn-events-mdx": () => import("./../../../src/pages/learn/events.mdx" /* webpackChunkName: "component---src-pages-learn-events-mdx" */),
  "component---src-pages-learn-tutorials-mdx": () => import("./../../../src/pages/learn/tutorials.mdx" /* webpackChunkName: "component---src-pages-learn-tutorials-mdx" */)
}

