import Layout from "../../../../src/components/Layout";
import { DataPageSidebarLinks } from "../../../../src/helpers/constants";
import CycleDiagramSpanish from "../../../../src/images/report-card-tracker-es.png";
import ReportCardExplorer from "../../../../src/components/ReportCardExplorer";
import { Link } from 'gatsby';
import Map from "../../../../src/images/eew-map.webp";
import EEWAuthorshipImage from "../../../../src/images/EEW-Authorship.png";
import { HouseEnergyAndCommerceCommittee } from "../../../../src/helpers/2019-house-energy-and-commerce-committee";
import { SenateEnvironmentAndPublicWorksCommitteeData } from "../../../../src/helpers/2019-senate-environmental-and-public-works-committee";
import * as React from 'react';
export default {
  Layout,
  DataPageSidebarLinks,
  CycleDiagramSpanish,
  ReportCardExplorer,
  Link,
  Map,
  EEWAuthorshipImage,
  HouseEnergyAndCommerceCommittee,
  SenateEnvironmentAndPublicWorksCommitteeData,
  React
};