import Layout from "../../../../src/components/Layout";
import InfoCard from "../../../../src/components/InfoCard";
import { LearnPageSidebarLinks } from "../../../../src/helpers/constants";
import intro from "../../../../src/images/tutorials-intro.png";
import jupyter from "../../../../src/images/tutorials-jupyter.png";
import watershed from "../../../../src/images/tutorials-watershed.png";
import watersheddoc from "../../../../src/images/tutorials-watersheddoc.png";
import { Link } from 'gatsby';
import * as React from 'react';
export default {
  Layout,
  InfoCard,
  LearnPageSidebarLinks,
  intro,
  jupyter,
  watershed,
  watersheddoc,
  Link,
  React
};